import React, { useEffect, useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Select from "react-select";
import moment from "moment-timezone";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getVisitorsExternalDataList,
  getVisitorsExternalAcquisitionOffers,
  getT2DataProxyCall,
  exportAcquisitionData,
  getVisitorsExternalDataStats,
  getInventoryStatus,
  getClickInsReport,
  listVisitorsExternalData,
} from "../../../actions/MyTrafficNotificationActions";
import Util from "../../../util/Util";
import {
  IVisitorExternalData,
  IStore,
  AcquisitionOfferDto,
  VisitorExternalDataStatsDto,
  ProspectResponseDto,
} from "../../../../index.dts";
import { sendInstantAcquisitionEmail } from "../../../actions/autoAcquireActions";
import { PERMISSIONS, SEARCH_OPERATIONS } from "../../../util/Enums";
import { ResponseT2Data, RequestT2Dto } from "./index";
import { LeadFileDownloader } from "../../../services/file_export/LeadFileDownloader";
import { IDS } from "../../../views/constants";
import "./acquisitions.css";
import { PERIODS } from "../../../util/common";
import { DateRangePickerWidget } from "../../data_range_picker/DateRangePickerWidget";
import { DateRangePeriods } from "../../data_range_picker/date_range_periods";
import { RadioFilter } from "../../radio_filter/RadioFilter";
import { isNull } from "lodash";
import Spinner from "../../spinner/Spinner";
import CopyToClipboard from "react-copy-to-clipboard";
import ImageGallery from "./ImageGallery";
import { NumericFormat } from "react-number-format";

interface IProps {
  dealershipId: number;
  dealershipName: string;
}

// enum AcquisitionSource {
//   MY_ACQUISITIONS_SERVICE,
//   MY_ACQUISITIONS_SALE,
//   all,
// }

enum AcquisitionSource {
  MY_ACQUISITIONS_SERVICE,
  MY_ACQUISITIONS_SALE,
  all,
}

// enum AcquisitionSourceLabel {
//   MY_ACQUISITIONS_SERVICE = "MY_ACQUISITIONS_SERVICE",
//   MY_ACQUISITIONS_SALE = "MY_ACQUISITIONS_SALE",
// }

enum AcquisitionSourceLabel {
  MY_ACQUISITIONS_SERVICE = "MY_ACQUISITIONS_SERVICE",
  MY_ACQUISITIONS_SALE = "MY_ACQUISITIONS_SALE",
}

let searchTimeout: any;
let searchTimeoutInterval: number = 0;

const instantAcquisitionDefaultText = `Hi \${customerFirstName} \${customerLastName},

I am \${usedCarManagerName}. Our records indicate, that you or someone you know may have a \${vehicleYear} \${vehicleMake} \${vehicleModel} that I'd like to buy from you. If you are interested in selling or trading your vehicle, I will give you $500 above its market value. I appreciate your time!`;

const instantAcquisitionVariables = [
  { key: "customerFirstName", description: "Customer's first name" },
  { key: "customerLastName", description: "Customer's last name" },
  { key: "usedCarManagerName", description: "Used Car Manager's name" },
  { key: "vehicleYear", description: "Customer's vehicle year" },
  { key: "vehicleMake", description: "Customer's vehicle make" },
  { key: "vehicleModel", description: "Customer's vehicle model" },
  { key: "vehicleVin", description: "Customer's vehicle model" },
];

const Acquisitions = (props: IProps, ref: any) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  // const [calendarFilter, setCalendarFilter] = useState<any>({
  //   period: (() => {
  //     const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
  //     return {
  //       start: period.start,
  //       end: period.end,
  //     };
  //   })(),
  // });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [acquisitionOffers, setAcquisitionOffers] = useState<
    AcquisitionOfferDto[]
  >([]);
  const [showAcquisitionOffers, setShowAcquisitionOffers] =
    useState<boolean>(false);
  const [showLeadsGrid, setShowLeadsGrid] = useState<boolean>(false);
  const [showClickInsReport, setShowClickInsReport] = useState<boolean>(false);
  const [inventoryStatus, setInventoryStatus] = useState<boolean>(false);
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(Util.dataGridDefaultLimit);
  const [pageLeads, setPageLeads] = useState<number>(0);
  const [limitLeads, setLimitLeads] = useState<number>(
    Util.dataGridDefaultLimit
  );
  const [firstLeads, setFirstLeads] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [totalItemsLeadsCount, setTotalItemsLeadsCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLeads, setLoadingLeads] = useState<boolean>(true);
  const dt = useRef<DataTable>(null);
  const [sortMap] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(
    new Map([
      [
        "created",
        {
          field: "created",
          order: -1,
        },
      ],
    ])
  );

  const [sortMapLeads] = useState<
    Map<string, { field: string; order: DataTableSortOrderType }>
  >(
    new Map([
      [
        "prospectLeadDate",
        {
          field: "prospectLeadDate",
          order: -1,
        },
      ],
    ])
  );

  const dealershipContext = useSelector(
    (store: IStore) => store.auth.dealershipContext,
    shallowEqual
  );

  // Filters
  const [phoneFilterValue, setPhoneFilterValue] = useState<string>("");
  const [emailFilterValue, setEmailFilterValue] = useState<string>("");
  const [chosenSource, setChosenSource] = useState<any>(AcquisitionSource.all);
  const [fullnameFilterValue, setFullnameFilterValue] = useState<string>("");
  const [fullnameLeadsFilterValue, setFullnameLeadsFilterValue] =
    useState<string>("");
  const [emailLeadsFilterValue, setEmailLeadsFilterValue] =
    useState<string>("");
  const [phoneLeadsFilterValue, setPhoneLeadsFilterValue] =
    useState<string>("");
  const [sourceLeadsFilterValue, setSourceLeadsFilterValue] =
    useState<string>("");
  const [leadStatusFilterValue, setLeadStatusFilterValue] =
    useState<string>("");
  const [upTypeFilterValue, setUpTypeFilterValue] = useState<string>("");
  const [vehicleMakeFilterValue, setVehicleMakeFilterValue] =
    useState<string>("");
  const [vehicleYearFilterValue, setVehicleYearFilterValue] =
    useState<string>("");
  const [vehicleModelFilterValue, setVehicleModelFilterValue] =
    useState<string>("");
  const [vehicleDatePurchasedFilterValue, setVehicleDatePurchasedFilterValue] =
    useState<string>("");
  const [vehicleSeriesFilterValue, setVehicleSeriesFilterValue] =
    useState<string>("");
  const [inspectionStatusFilterValue, setInspectionStatusFilterValue] =
    useState<string>("");
  const [vehiclePlateNumberFilterValue, setVehiclePlateNumberFilterValue] =
    useState<string>("");
  const [vehicleVinFilterValue, setVehicleVinFilterValue] =
    useState<string>("");
  const [vehicleVehicleTypeFilterValue, setVehicleVehicleTypeFilterValue] =
    useState<string>("");
  const [offerPriceFilterValue, setOfferPriceFilterValue] =
    useState<string>("");
  const [sourceFilterValue, setSourceFilterValue] = useState<string>("");
  const [sourceFilterValue2, setSourceFilterValue2] = useState<string>("");
  const [piiAddressFilterValue, setPiiAddressFilterValue] =
    useState<string>("");
  const [piiCityFilterValue, setPiiCityFilterValue] = useState<string>("");
  const [piiStateFilterValue, setPiiStateFilterValue] = useState<string>("");
  const [piiZipCodeFilterValue, setPiiZipCodeFilterValue] =
    useState<string>("");
  const [purchasedDatePeriod, setPurchasedDatePeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });
  const [endDateExport, setEndDateExport] = useState<any>(
    PERIODS.LAST_30_DAYS.to
  );
  const [startDateExport, setStartDateExport] = useState<any>(
    PERIODS.LAST_30_DAYS.from
  );
  const period = DateRangePeriods.get(DateRangePeriods.keys.last30Days);
  const [dateCreated, setDateCreated] = useState<any>({
    period: (() => {
      return {
        start: period.start,
        end: period.end,
      };
    })(),
  });
  const [dateLeads, setDateLeads] = useState<any>({
    period: (() => {
      return {
        start: period.start,
        end: period.end,
      };
    })(),
  });
  const [emailedDatePeriod, setEmailedDatePeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });
  const [acquiredDatePeriod, setAcquiredDatePeriod] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start: undefined,
    end: undefined,
  });
  const [externalDataStats, setExternalDataStats] =
    useState<VisitorExternalDataStatsDto>();
  const [fieldsToFilterExport, setFieldsToFilterExport] = useState<
    { label: string; value: string }[]
  >([]);

  // T2 call
  const [visitorsExternalData, setVisitorsExternalData] = useState<
    IVisitorExternalData[]
  >([]);
  const [acquisitionLeads, setAcquisitionLeads] = useState<
    ProspectResponseDto[]
  >([]);

  const [visitorsT2ExternalData, setVisitorsT2ExternalData] =
    useState<ResponseT2Data>();
  const [t2ModalVisible, setT2ModalVisible] = useState<boolean>(false);
  const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [t2PhoneNumber, setT2PhoneNumber] = useState<string>("");
  const [t2Email, setT2Email] = useState<string>("");
  const vehicleOrderFields = [
    "year",
    "make",
    "model",
    "series",
    "stock",
    "plate_number",
    "vin",
    "vehicle_type",
  ];
  const [clickInsReport, setClickInsReport] = useState<any>();
  // Instant Acquisition logic starts
  const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<any[]>([]);
  const [combinedEmailsString, setCombinedEmailsString] = useState<string>("");
  const [instantAcquisitionEmailSubject, setInstantAcquisitionEmailSubject] =
    useState<string>("");
  const [instantAcquisitionEmailBodyText, setInstantAcquisitionEmailBodyText] =
    useState(instantAcquisitionDefaultText);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, selectionStart } = e.target;
    setCursorPosition(selectionStart);

    // Detect if "$" is the last typed character anywhere
    if (value[selectionStart - 1] === "$") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    setInstantAcquisitionEmailBodyText(value);
  };

  const handleSelectVariable = (variable: string) => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const start = instantAcquisitionEmailBodyText.slice(0, cursorPosition - 1); // Remove the "$"
    const end = instantAcquisitionEmailBodyText.slice(cursorPosition);
    const newText = `${start}\${${variable}}${end}`;

    setInstantAcquisitionEmailBodyText(newText);
    setShowDropdown(false);

    // Set cursor position after inserted text
    setTimeout(() => {
      textarea.setSelectionRange(
        start.length + variable.length + 3,
        start.length + variable.length + 3
      );
      textarea.focus();
    }, 0);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInstantAcquisitionModalOpen, setIsInstantAcquisitionModalOpen] =
    useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    let idsToSend: number[] = [];
    let customers = selectedCustomers.reduce((acc, customer) => {
      idsToSend.push(customer.id);
      acc[customer.id] = {
        ...customer, // Keep all properties of the customer object
        piiEmails: Util.getStringArray(customer.piiEmails)?.[0]
          ? [Util.getStringArray(customer.piiEmails)?.[0]]
          : [],
      };
      return acc;
    }, {} as Record<string, (typeof selectedCustomers)[number]>); // Type-safe dictionary

    setSelectedCustomerIds(idsToSend);

    const allEmails = Object.values(customers).flatMap(
      (customer) => (customer as { piiEmails?: string[] }).piiEmails || []
    );
    const uniqueEmails = Array.from(new Set(allEmails));
    const combinedString = uniqueEmails.join(", ");
    setCombinedEmailsString(combinedString);
  }, [selectedCustomers]);
  // Instant Acquisition logic ends

  const onClearLeads = () => {
    sortMapLeads.clear();
    setDateLeads({
      period: {
        start: moment(period?.start)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate(),
        end: moment(period?.end)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate(),
      },
    });
    setFullnameLeadsFilterValue("");
    setEmailLeadsFilterValue("");
    setPhoneLeadsFilterValue("");
    setSourceLeadsFilterValue("");
  };

  const onClear = () => {
    sortMap.clear();
    setDateCreated({
      period: {
        start: moment(period?.start)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate(),
        end: moment(period?.end)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate(),
      },
    });
    setEmailedDatePeriod({
      start: undefined,
      end: undefined,
    });
    setOfferPriceFilterValue("");
    setAcquiredDatePeriod({
      start: undefined,
      end: undefined,
    });
    setLeadStatusFilterValue("");
    setUpTypeFilterValue("");
    setPhoneFilterValue("");
    setEmailFilterValue("");
    setFullnameFilterValue("");
    setVehicleMakeFilterValue("");
    setVehicleYearFilterValue("");
    setSourceFilterValue("");
    setSourceFilterValue2("");
    setVehicleModelFilterValue("");
    setVehicleDatePurchasedFilterValue("");
    setVehicleSeriesFilterValue("");
    setInspectionStatusFilterValue("");
    setVehiclePlateNumberFilterValue("");
    setVehicleVinFilterValue("");
    setVehicleVehicleTypeFilterValue("");
    setPiiAddressFilterValue("");
    setPiiCityFilterValue("");
    setPiiStateFilterValue("");
    setPiiZipCodeFilterValue("");
    setPurchasedDatePeriod({
      start: undefined,
      end: undefined,
    });
    setT2PhoneNumber("");
    setT2Email("");
  };

  const { t }: any = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    getT2Data(t2PhoneNumber, t2Email);
  };

  const handleExportSubmit = (e) => {
    e.preventDefault();
    setExportModalVisible(true);
  };

  useEffect(() => {
    if (props.dealershipId)
      getInventoryStatus(props.dealershipId).then((response) => {
        setInventoryStatus(response?.data?.active);
      });
  }, [props?.dealershipId]);

  useEffect(() => {
    let newStartDate = new Date(dateCreated?.period?.start);
    newStartDate.setUTCHours(24, 0, 0, 0);
    let newEndDate = new Date(dateCreated?.period?.end);
    newEndDate.setUTCHours(23, 59, 59, 59);

    getVisitorsExternalDataStats({
      dealershipId: props?.dealershipId,
      endDate: newEndDate, //endDate,
      startDate: newStartDate, //startDate,
      acquisitionMethod:
        chosenSource === 1
          ? AcquisitionSourceLabel.MY_ACQUISITIONS_SALE
          : chosenSource === 0
          ? AcquisitionSourceLabel.MY_ACQUISITIONS_SERVICE
          : null,
    }).then((response) => {
      setExternalDataStats(response?.data);
    });
  }, [
    chosenSource,
    dateCreated?.period?.end,
    dateCreated?.period?.start,
    props.dealershipId,
  ]);

  useEffect(() => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData(props.dealershipId, 0, limit).finally(() => {
        setPage(0);
        setFirst(0);
        searchTimeoutInterval = 500;
      });
    }, searchTimeoutInterval);
    // eslint-disable-next-line
  }, [
    props.dealershipId,
    dateCreated?.period?.start,
    dateCreated?.period?.end,
    phoneFilterValue,
    emailFilterValue,
    fullnameFilterValue,
    leadStatusFilterValue,
    upTypeFilterValue,
    piiCityFilterValue,
    vehicleMakeFilterValue,
    vehicleYearFilterValue,
    vehicleModelFilterValue,
    vehicleDatePurchasedFilterValue,
    vehicleSeriesFilterValue,
    vehiclePlateNumberFilterValue,
    vehicleVinFilterValue,
    vehicleVehicleTypeFilterValue,
    piiAddressFilterValue,
    offerPriceFilterValue,
    sourceFilterValue,
    sourceFilterValue2,
    piiStateFilterValue,
    piiZipCodeFilterValue,
    purchasedDatePeriod,
    emailedDatePeriod,
    acquiredDatePeriod,
    inspectionStatusFilterValue,
  ]);

  useEffect(() => {
    getLeadsData(0, limitLeads).finally(() => {
      setPageLeads(0);
      setFirstLeads(0);
    });
  }, [
    sourceLeadsFilterValue,
    dealershipContext?.id,
    emailLeadsFilterValue,
    dateLeads?.period?.end,
    fullnameLeadsFilterValue,
    phoneLeadsFilterValue,
    sourceLeadsFilterValue,
    dateLeads?.period?.start,
  ]);
  // Function to parse years and ranges from input
  const parseYears = (input: string): number[] => {
    const years: number[] = [];
    const ranges = input?.split(",").map((range) => range.trim());

    ranges.forEach((range) => {
      if (range.includes("-")) {
        // Handle ranges like "2015-2018"
        const [start, end] = range?.split("-").map(Number);
        if (!isNaN(start) && !isNaN(end) && start <= end) {
          for (let year = start; year <= end; year++) {
            years.push(year);
          }
        }
      } else {
        // Handle individual years like "2012"
        const year = Number(range);
        if (!isNaN(year)) {
          years.push(year);
        }
      }
    });

    return years;
  };

  const getData = (dealershipId: number, page: number, limit: number) =>
    new Promise((resolve, reject) => {
      const filter: any[] = [
        {
          field: "dealershipId",
          value: Number(dealershipId),
          operation: SEARCH_OPERATIONS.EQUAL,
        },
      ];

      if (phoneFilterValue) {
        filter.push({
          field: "piiPhone",
          value: phoneFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (emailFilterValue) {
        filter.push({
          field: "piiEmails",
          value: emailFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (fullnameFilterValue) {
        filter.push({
          field: "piiFullName",
          value: fullnameFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (leadStatusFilterValue) {
        filter.push({
          field: "leadStatus",
          value: leadStatusFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (upTypeFilterValue) {
        filter.push({
          field: "upType",
          value: upTypeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehicleMakeFilterValue) {
        filter.push({
          field: "vehicleMake",
          value: vehicleMakeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehicleYearFilterValue) {
        // filter.push({
        //   field: "vehicleYear",
        //   value: vehicleYearFilterValue,
        //   operation: SEARCH_OPERATIONS.EQUAL,
        // });
        const years = parseYears(vehicleYearFilterValue);
        years.forEach((year) => {
          filter.push({
            field: "vehicleYear",
            value: year,
            operation: SEARCH_OPERATIONS.EQUAL,
          });
        });
      }

      if (vehicleModelFilterValue) {
        filter.push({
          field: "vehicleModel",
          value: vehicleModelFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehicleSeriesFilterValue) {
        filter.push({
          field: "vehicleSeries",
          value: vehicleSeriesFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (inspectionStatusFilterValue) {
        filter.push({
          field: "inspectionStatus",
          value: inspectionStatusFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehiclePlateNumberFilterValue) {
        filter.push({
          field: "vehiclePlateNumber",
          value: vehiclePlateNumberFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehicleVinFilterValue) {
        filter.push({
          field: "vehicleVin",
          value: vehicleVinFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (vehicleVehicleTypeFilterValue) {
        filter.push({
          field: "vehicleVehicleType",
          value: vehicleVehicleTypeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (piiAddressFilterValue) {
        filter.push({
          field: "piiAddress",
          value: piiAddressFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (offerPriceFilterValue) {
        filter.push({
          field: "offerPrice",
          value: offerPriceFilterValue,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (sourceFilterValue2) {
        filter.push({
          field: "acquisitionMethod",
          value: sourceFilterValue2,
          operation: SEARCH_OPERATIONS.EQUAL,
        });
      }

      if (sourceFilterValue) {
        filter.push({
          field: "source",
          value: sourceFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (piiCityFilterValue) {
        filter.push({
          field: "piiCity",
          value: piiCityFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (piiStateFilterValue) {
        filter.push({
          field: "piiState",
          value: piiStateFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (piiZipCodeFilterValue) {
        filter.push({
          field: "piiZipcode",
          value: piiZipCodeFilterValue,
          operation: SEARCH_OPERATIONS.LIKE,
        });
      }

      if (purchasedDatePeriod.start && purchasedDatePeriod.end) {
        const dateToSend = `${moment(purchasedDatePeriod.start).format(
          Util.localDateTimeFormat
        )},${moment(purchasedDatePeriod.end).format(Util.localDateTimeFormat)}`;
        filter.push({
          field: "vehicleDatePurchased",
          operation: SEARCH_OPERATIONS.EQUAL,
          value: dateToSend,
        });
      }

      if (dateCreated?.period?.start && dateCreated?.period?.end) {
        let dateToSend = `${moment(dateCreated?.period?.start)
          .set({ hour: 0, minute: 0, seconds: 0 })
          .format(Util.localDateTimeFormat)},${moment(dateCreated?.period?.end)
          .set({ hour: 23, minute: 59, seconds: 59 })
          .format(Util.localDateTimeFormat)}`;
        filter.push({
          field: "created",
          operation: SEARCH_OPERATIONS.EQUAL,
          value: dateToSend,
        });
      }

      if (emailedDatePeriod.start && emailedDatePeriod.end) {
        const dateToSend = `${moment(emailedDatePeriod.start).format(
          Util.localDateTimeFormat
        )},${moment(emailedDatePeriod.end).format(Util.localDateTimeFormat)}`;
        filter.push({
          field: "firstEmailSentTs",
          operation: SEARCH_OPERATIONS.EQUAL,
          value: dateToSend,
        });
      }

      if (acquiredDatePeriod.start && acquiredDatePeriod.end) {
        const dateToSend = `${moment(acquiredDatePeriod.start).format(
          Util.localDateTimeFormat
        )},${moment(acquiredDatePeriod.end).format(Util.localDateTimeFormat)}`;
        filter.push({
          field: "acquiredDate",
          operation: SEARCH_OPERATIONS.EQUAL,
          value: dateToSend,
        });
      }

      setLoading(true);
      getVisitorsExternalDataList({
        paging: {
          page: page,
          pageLimit: limit,
        },
        filter: filter,
        sorting: Array.from(sortMap.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
      })
        .then((response) => {
          setTotalItemsCount(response.data.totalElements);
          setVisitorsExternalData(response.data.content);
          resolve(response);
        })
        .catch((error) => Util.showError(error))
        .finally(() => setLoading(false));
    });

  // T2 API Call
  const getT2Data = (phoneNumber: string, email: string) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      getT2DataProxyCall(phoneNumber, dealershipContext?.externalRefId, email)
        .then((response) => {
          setLoading(false);
          if (response.data.Vehicles === null) {
            Util.warning("Data not found with that search criteria.");
          } else {
            setVisitorsT2ExternalData(response.data);
            setT2ModalVisible(true);
            resolve(response);
          }
        })
        .catch((error) => {
          Util.warning("Data not found with that search criteria.");
          console.error("Error sending data:", error);
          setLoading(false);
        });
    });

  const formatDateTime = (dateTime: any) => {
    const [datePart, timePart] = dateTime?.split(" ");
    const [month, day, year] = datePart?.split("/");
    const [hours, minutes, seconds] = timePart?.split(":");

    const date = new Date(year, month - 1, day, hours, minutes, seconds);

    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/Chicago", // CST timezone
    };

    const formattedTime = date.toLocaleTimeString("en-US", options);
    return `${datePart} ${formattedTime} CST`;
  };

  const onHideT2Modal = (): void => {
    setT2ModalVisible(false);
  };

  const onHideExportModal = (): void => {
    setExportModalVisible(false);
    setFieldsToFilterExport([]);
  };

  const onPage = (event: any) => {
    getData(props.dealershipId, event.page, event.rows).finally(() => {
      setPage(event.page);
      setLimit(event.rows);
      setFirst(event.first);
    });
  };

  const onPageLeads = (event: any) => {
    getLeadsData(event.page, event.rows).finally(() => {
      setPageLeads(event.page);
      setLimitLeads(event.rows);
      setFirstLeads(event.first);
    });
  };

  const onSort = (data: any) => {
    sortMap.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMap.set(item.field, item);
      });
      getData(props.dealershipId, page, limit);
    }
  };

  const onSortLeads = (data: any) => {
    sortMapLeads.clear();
    if (data && Array.isArray(data.multiSortMeta)) {
      data.multiSortMeta.forEach((item: any) => {
        sortMapLeads.set(item.field, item);
      });
      getLeadsData(pageLeads, limitLeads);
    }
  };

  const handleCellWithoutClickEvents = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const phoneFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPhoneFilterValue(e.target.value);
      }}
      value={phoneFilterValue}
    />
  );

  const emailFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setEmailFilterValue(e.target.value);
      }}
      value={emailFilterValue}
    />
  );

  const fullnameFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setFullnameFilterValue(e.target.value);
      }}
      value={fullnameFilterValue}
    />
  );

  const fullnameLeadsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setFullnameLeadsFilterValue(e.target.value);
      }}
      value={fullnameLeadsFilterValue}
    />
  );

  const emailLeadsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setEmailLeadsFilterValue(e.target.value);
      }}
      value={emailLeadsFilterValue}
    />
  );

  const phoneLeadsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPhoneLeadsFilterValue(e.target.value);
      }}
      value={phoneLeadsFilterValue}
    />
  );

  const sourceLeadsFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setSourceLeadsFilterValue(e.target.value);
      }}
      value={sourceLeadsFilterValue}
    />
  );

  const leadStatusFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setLeadStatusFilterValue(e.target.value);
      }}
      value={leadStatusFilterValue}
    />
  );

  const upTypeFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setUpTypeFilterValue(e.target.value);
      }}
      value={upTypeFilterValue}
    />
  );

  const vehicleMakeFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehicleMakeFilterValue(e.target.value);
      }}
      value={vehicleMakeFilterValue}
    />
  );

  const vehicleYearFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setVehicleYearFilterValue(e.target.value); // Update state with input text
      }}
      value={vehicleYearFilterValue}
      placeholder="Enter years or ranges (e.g., 2012,2015-2018)"
    />
  );

  const sourceFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setSourceFilterValue(e.target.value);
      }}
      value={sourceFilterValue}
    />
  );

  const vehicleModelFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehicleModelFilterValue(e.target.value);
      }}
      value={vehicleModelFilterValue}
    />
  );

  const createdTemplate = (data: any) => {
    let date = moment(data.vehicleDatePurchased).format(Util.usDateTimeFormat);
    return (
      <div onClick={handleCellWithoutClickEvents}>
        {isNull(data.vehicleDatePurchased) ? "" : date}
      </div>
    );
  };

  const emailDateTemplate = (data: any) => {
    let date = moment(data.firstEmailSentTs).format(Util.usDateTimeFormat);
    return (
      <div onClick={handleCellWithoutClickEvents}>
        {isNull(data.firstEmailSentTs) ? "" : date}
      </div>
    );
  };

  const acquiredDateTemplate = (data: any) => {
    let date = moment(data.acquiredDate).format(Util.usDateTimeFormat);
    return (
      <div onClick={handleCellWithoutClickEvents}>
        {isNull(data.acquiredDate) ? "" : date}
      </div>
    );
  };

  const dateCreatedTemplate = (data: any) => {
    let date = moment(data.created).format(Util.usDateTimeFormat);
    return <div>{isNull(data.created) ? "" : date}</div>;
  };

  const dateCreatedOffersTemplate = (data: any) => {
    let date = moment(data?.created).format(Util.usDateTimeFormat);
    return <div>{isNull(data?.created) ? "" : date}</div>;
  };

  const dateLeadsTemplate = (data: any) => {
    let date = moment(data.prospectLeadDate).format(Util.usDateTimeFormat);
    return <div>{isNull(data.prospectLeadDate) ? "" : date}</div>;
  };

  const dateFilter = (
    <DateRangePickerWidget
      start={purchasedDatePeriod.start}
      end={purchasedDatePeriod.end}
      ranges={DateRangePeriods.options}
      onChange={(start?: Date, end?: Date): void => {
        setPurchasedDatePeriod({
          start: start
            ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate()
            : undefined,
          end: end
            ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate()
            : undefined,
        });
      }}
    />
  );

  const emailedDateFilter = (
    <DateRangePickerWidget
      start={emailedDatePeriod.start} // Start date from emailedDatePeriod
      end={emailedDatePeriod.end} // End date from emailedDatePeriod
      ranges={DateRangePeriods.options} // Predefined ranges
      onChange={(start?: Date, end?: Date): void => {
        // Handle changes to the selected date range
        setEmailedDatePeriod({
          start: start
            ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate()
            : undefined,
          end: end
            ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate()
            : undefined,
        });
      }}
    />
  );

  const acquiredDateFilter = (
    <DateRangePickerWidget
      start={acquiredDatePeriod.start} // Start date from acquiredDatePeriod
      end={acquiredDatePeriod.end} // End date from acquiredDatePeriod
      ranges={DateRangePeriods.options} // Predefined date ranges
      onChange={(start?: Date, end?: Date): void => {
        // Update the acquiredDatePeriod state when a date range is selected
        setAcquiredDatePeriod({
          start: start
            ? moment(start).set({ hour: 0, minute: 0, second: 0 }).toDate()
            : undefined,
          end: end
            ? moment(end).set({ hour: 23, minute: 59, second: 59 }).toDate()
            : undefined,
        });
      }}
    />
  );

  const dateCreatedFilter = (
    <DateRangePickerWidget
      start={dateCreated?.period?.start}
      end={dateCreated?.period?.end}
      ranges={DateRangePeriods.options}
      onChange={(start?: Date, end?: Date) => {
        setDateCreated({
          period: {
            start: moment(start)
              .set({
                hour: 0,
                minute: 0,
                seconds: 0,
              })
              .toDate(),
            end: moment(end)
              .set({
                hour: 23,
                minute: 59,
                seconds: 59,
              })
              .toDate(),
          },
        });
      }}
    />
  );

  const dateLeadsFilter = (
    <DateRangePickerWidget
      start={dateLeads?.period?.start}
      end={dateLeads?.period?.end}
      ranges={DateRangePeriods.options}
      onChange={(start?: Date, end?: Date) => {
        setDateLeads({
          period: {
            start: moment(start)
              .set({
                hour: 0,
                minute: 0,
                seconds: 0,
              })
              .toDate(),
            end: moment(end)
              .set({
                hour: 23,
                minute: 59,
                seconds: 59,
              })
              .toDate(),
          },
        });
      }}
    />
  );

  const vehiclePlateNumberFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehiclePlateNumberFilterValue(e.target.value);
      }}
      value={vehiclePlateNumberFilterValue}
    />
  );

  const vehicleSeriesFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehicleSeriesFilterValue(e.target.value);
      }}
      value={vehicleSeriesFilterValue}
    />
  );

  const inspectionStatusFilter = (
    <Dropdown
      value={inspectionStatusFilterValue}
      options={[
        { label: t("ALL"), value: "" },
        { label: t("LINK SENT"), value: "LINK_SENT" },
        { label: t("COMPLETED"), value: "COMPLETED" },
      ]}
      style={{ width: "100%", boxShadow: "none" }}
      onChange={(e: any) => {
        setInspectionStatusFilterValue(e.target.value);
      }}
    />
  );

  const vehicleVinFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehicleVinFilterValue(e.target.value);
      }}
      value={vehicleVinFilterValue}
    />
  );

  const vehicleVehicleTypeFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setVehicleVehicleTypeFilterValue(e.target.value);
      }}
      value={vehicleVehicleTypeFilterValue}
    />
  );

  const offerPriceFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setOfferPriceFilterValue(e.target.value);
      }}
      value={offerPriceFilterValue}
    />
  );

  const piiAddressFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPiiAddressFilterValue(e.target.value);
      }}
      value={piiAddressFilterValue}
    />
  );

  const piiCityFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPiiCityFilterValue(e.target.value);
      }}
      value={piiCityFilterValue}
    />
  );

  const piiStateFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPiiStateFilterValue(e.target.value);
      }}
      value={piiStateFilterValue}
    />
  );

  const piiZipCodeFilter = (
    <InputText
      style={{ width: "100%" }}
      onChange={(e: any) => {
        setPiiZipCodeFilterValue(e.target.value);
      }}
      value={piiZipCodeFilterValue}
    />
  );

  const getPrefix = (): string => {
    let prefix: string;

    if (props.dealershipId === 0) {
      prefix = "All_dealers";
    } else {
      prefix = props.dealershipName.replaceAll(" ", "_");
    }

    return prefix;
  };

  const getSuffix = (startDate: string, endDate: string): string => {
    return `from_${moment(startDate).format("DD.MMMM.YYYY")}_to_${moment(
      endDate
    ).format("DD.MMMM.YYYY")}`;
  };

  const onSubmitInstantAcquisitionEmail = (): void => {
    Util.globalSpinner().show();
    sendInstantAcquisitionEmail({
      dealershipId: dealershipContext.id,
      recipients: selectedCustomerIds,
      subject: instantAcquisitionEmailSubject,
      body: instantAcquisitionEmailBodyText,
    })
      .then((response) => {
        Util.globalSpinner().hide();
        if (response.status === 200) {
          if (response.data?.Items?.length > 0) {
            Util.warning(
              `${response.data.Message} Invalid emails: ${response.data.Items}`
            );
            Util.globalSpinner().hide();
          } else {
            Util.success(response.data.Message);
          }
          setSelectedCustomers([]);
          setCombinedEmailsString("");
          setSelectedCustomerIds([]);
          setInstantAcquisitionEmailSubject("");
          setInstantAcquisitionEmailBodyText(instantAcquisitionDefaultText);
          setIsInstantAcquisitionModalOpen(false);
        } else {
          setSelectedCustomers([]);
          setSelectedCustomerIds([]);
          setCombinedEmailsString("");
          setInstantAcquisitionEmailSubject("");
          setInstantAcquisitionEmailBodyText(instantAcquisitionDefaultText);
          setIsInstantAcquisitionModalOpen(false);
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.entries(errors).forEach(([key, value]) => {
            Util.error(`${value}`);
          });
        } else if (error?.response?.data?.message) {
          Util.error(error.response.data.message);
        } else {
          Util.showError(error);
        }
        Util.globalSpinner().hide();
      });
  };

  const onExport = (): void => {
    const filter: any[] = [
      {
        field: "dealershipId",
        value: Number(props.dealershipId),
        operation: SEARCH_OPERATIONS.EQUAL,
      },
    ];

    if (phoneFilterValue) {
      filter.push({
        field: "piiPhone",
        value: phoneFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (emailFilterValue) {
      filter.push({
        field: "piiEmails",
        value: emailFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (fullnameFilterValue) {
      filter.push({
        field: "piiFullName",
        value: fullnameFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (leadStatusFilterValue) {
      filter.push({
        field: "leadStatus",
        value: leadStatusFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (upTypeFilterValue) {
      filter.push({
        field: "upType",
        value: upTypeFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehicleMakeFilterValue) {
      filter.push({
        field: "vehicleMake",
        value: vehicleMakeFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehicleYearFilterValue) {
      filter.push({
        field: "vehicleYear",
        value: vehicleYearFilterValue,
        operation: SEARCH_OPERATIONS.EQUAL,
      });
    }

    if (vehicleModelFilterValue) {
      filter.push({
        field: "vehicleModel",
        value: vehicleModelFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehicleSeriesFilterValue) {
      filter.push({
        field: "vehicleSeries",
        value: vehicleSeriesFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (inspectionStatusFilterValue) {
      filter.push({
        field: "inspectionStatus",
        value: inspectionStatusFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehiclePlateNumberFilterValue) {
      filter.push({
        field: "vehiclePlateNumber",
        value: vehiclePlateNumberFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehicleVinFilterValue) {
      filter.push({
        field: "vehicleVin",
        value: vehicleVinFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (vehicleVehicleTypeFilterValue) {
      filter.push({
        field: "vehicleVehicleType",
        value: vehicleVehicleTypeFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (piiAddressFilterValue) {
      filter.push({
        field: "piiAddress",
        value: piiAddressFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (offerPriceFilterValue) {
      filter.push({
        field: "offerPrice",
        value: offerPriceFilterValue,
        operation: SEARCH_OPERATIONS.EQUAL,
      });
    }

    if (sourceFilterValue2) {
      filter.push({
        field: "acquisitionMethod",
        value: sourceFilterValue2,
        operation: SEARCH_OPERATIONS.EQUAL,
      });
    }

    if (sourceFilterValue) {
      filter.push({
        field: "source",
        value: sourceFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (piiCityFilterValue) {
      filter.push({
        field: "piiCity",
        value: piiCityFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (piiStateFilterValue) {
      filter.push({
        field: "piiState",
        value: piiStateFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (piiZipCodeFilterValue) {
      filter.push({
        field: "piiZipcode",
        value: piiZipCodeFilterValue,
        operation: SEARCH_OPERATIONS.LIKE,
      });
    }

    if (purchasedDatePeriod.start && purchasedDatePeriod.end) {
      const dateToSend = `${moment(purchasedDatePeriod.start).format(
        Util.localDateTimeFormat
      )},${moment(purchasedDatePeriod.end).format(Util.localDateTimeFormat)}`;
      filter.push({
        field: "vehicleDatePurchased",
        operation: SEARCH_OPERATIONS.EQUAL,
        value: dateToSend,
      });
    }

    if (dateCreated?.period?.start && dateCreated?.period?.end) {
      let dateToSend = `${moment(dateCreated?.period?.start)
        .set({ hour: 0, minute: 0, seconds: 0 })
        .format(Util.localDateTimeFormat)},${moment(dateCreated?.period?.end)
        .set({ hour: 23, minute: 59, seconds: 59 })
        .format(Util.localDateTimeFormat)}`;
      filter.push({
        field: "created",
        operation: SEARCH_OPERATIONS.EQUAL,
        value: dateToSend,
      });
    }

    if (emailedDatePeriod.start && emailedDatePeriod.end) {
      const dateToSend = `${moment(emailedDatePeriod.start).format(
        Util.localDateTimeFormat
      )},${moment(emailedDatePeriod.end).format(Util.localDateTimeFormat)}`;
      filter.push({
        field: "firstEmailSentTs",
        operation: SEARCH_OPERATIONS.EQUAL,
        value: dateToSend,
      });
    }

    if (acquiredDatePeriod.start && acquiredDatePeriod.end) {
      const dateToSend = `${moment(acquiredDatePeriod.start).format(
        Util.localDateTimeFormat
      )},${moment(acquiredDatePeriod.end).format(Util.localDateTimeFormat)}`;
      filter.push({
        field: "acquiredDate",
        operation: SEARCH_OPERATIONS.EQUAL,
        value: dateToSend,
      });
    }
    const dealershipIds: any = [];
    const fields = fieldsToFilterExport.map((item) => item.value);

    if (props.dealershipId !== 0) {
      dealershipIds.push(props.dealershipId);
    }
    Util.globalSpinner().show();
    exportAcquisitionData({
      filter: filter,
      sorting: Array.from(sortMap.values()).map((item) => {
        return {
          field: item.field,
          direction: item.order === 1 ? "asc" : "desc",
        };
      }),
      dealershipIds: dealershipIds,
      fields: fields,
    })
      .then((response) => {
        const reportId = response.data.id;

        if (Util.isEmpty(reportId)) {
          Util.warning(Util.defaultErrorMessage);
          return;
        }

        setExportDisabled(false);
        const fileDownloader = new LeadFileDownloader(
          reportId,
          getPrefix(),
          getSuffix(dateCreated?.period?.start, dateCreated?.period?.end)
        );
        fileDownloader.downloadAcquisitionExport();
      })
      .catch((error) => {
        Util.showError(error);
      })
      .finally(() => {
        Util.globalSpinner().hide();
      });
  };

  const exportFieldValues: {
    label: string;
    value: string;
  }[] = [
    { label: "Year", value: "vehicleYear" },
    { label: "Make", value: "vehicleMake" },
    { label: "Model", value: "vehicleModel" },
    { label: "Trim", value: "vehicleSeries" },
    { label: "Plate Number", value: "vehiclePlateNumber" },
    { label: "Vin", value: "vehicleVin" },
    { label: "Type", value: "vehicleVehicleType" },
    { label: "Address", value: "piiAddress" },
    { label: "City", value: "piiCity" },
    { label: "State", value: "piiState" },
    { label: "Zip Code", value: "piiZipcode" },
    { label: "Phone", value: "piiPhone" },
    { label: "Emails", value: "piiEmails" },
    { label: "Full Name", value: "piiFullName" },
    { label: "Date Purchased", value: "vehicleDatePurchased" },
    { label: "Created", value: "created" },
    { label: "Offer Price", value: "offerPrice" },
    { label: "Acquisition Offers", value: "acquisitionOffers" },
    { label: "Acquired Date", value: "acquiredDate" },
    { label: "Source", value: "source" },
    { label: "Lead Status", value: "leadStatus" },
    { label: "Up Type", value: "upType" },
    { label: "Date First Email Sent", value: "firstEmailSentTs" },
    { label: "Inspection Status", value: "inspectionStatus" },
  ];

  const onPerformanceReport = () => {
    history.push(Util.PATH_NAMES.MY_TRAFFIC_PERFORMANCE_REPORT);
  };

  const onInstantAcquisition = () => {
    setIsInstantAcquisitionModalOpen(true);
  };

  const onHideInstantAcquisition = () => {
    setIsInstantAcquisitionModalOpen(false);
  };

  const onLeadsBubbleClick = (rowData: any): void => {
    setDateLeads({
      period: {
        start: moment(dateCreated?.period?.start)
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toDate(),
        end: moment(dateCreated?.period?.end)
          .set({
            hour: 23,
            minute: 59,
            seconds: 59,
          })
          .toDate(),
      },
    });
    getLeadsData(0, limitLeads, true).finally(() => {
      setPageLeads(0);
      setFirstLeads(0);
    });
    setShowLeadsGrid(true);
  };

  const getLeadsData = (page: number, limit: number, bubbleClicked?: boolean) =>
    new Promise((resolve, reject) => {
      let newStartLeadsDate = new Date(dateLeads?.period?.start);
      newStartLeadsDate.setUTCHours(24, 0, 0, 0);
      let newEndLeadsDate = new Date(dateLeads?.period?.end);
      newEndLeadsDate.setUTCHours(23, 59, 59, 59);
      //when bubble is clicked
      let newStartDate = new Date(dateCreated?.period?.start);
      newStartDate.setUTCHours(24, 0, 0, 0);
      let newEndDate = new Date(dateCreated?.period?.end);
      newEndDate.setUTCHours(23, 59, 59, 59);

      setLoadingLeads(true);
      listVisitorsExternalData({
        acquisitionMethod:
          chosenSource === 1
            ? AcquisitionSourceLabel.MY_ACQUISITIONS_SALE
            : chosenSource === 0
            ? AcquisitionSourceLabel.MY_ACQUISITIONS_SERVICE
            : null,
        dealershipId: dealershipContext?.id,
        email: emailLeadsFilterValue,
        endDate: bubbleClicked ? newEndDate : newEndLeadsDate,
        fullName: fullnameLeadsFilterValue,
        pageLimit: limit,
        pageNumber: page,
        phone: phoneLeadsFilterValue,
        sorting: Array.from(sortMapLeads.values()).map((item) => {
          return {
            field: item.field,
            direction: item.order === 1 ? "asc" : "desc",
          };
        }),
        source: sourceLeadsFilterValue,
        startDate: bubbleClicked ? newStartDate : newStartLeadsDate,
      })
        .then((response) => {
          setTotalItemsLeadsCount(response?.data?.totalElements);
          setAcquisitionLeads(response?.data?.content);
          resolve(response);
        })
        .finally(() => setLoadingLeads(false));
    });

  const onOfferPriceClick = (rowData: any): void => {
    setShowAcquisitionOffers(true);
    getVisitorsExternalAcquisitionOffers(rowData?.id)
      .then((response) => {
        setIsLoading(true);
        setAcquisitionOffers(
          response?.data?.map((offer) => {
            if (offer?.emailSentTs == null) offer.emailSentTs = "noemail";
            return offer;
          })
        );
      })
      .catch((error) => Util.showError(error))
      .finally(() => setIsLoading(false));
  };

  const onInspectionStatusClick = (rowData: any): void => {
    setShowClickInsReport(true);
    getClickInsReport(rowData?.id).then((response) => {
      setClickInsReport(response.data);
      // if (response?.data["inspectionCase"]?.pdfReportUrl)
      //   openInNewTab(response?.data["inspectionCase"].pdfReportUrl);
      // else Util.warning("No pdf!");
    });
  };
  function getSpinner() {
    return isLoading ? (
      <Spinner
        visible={isLoading}
        width={20}
        height={20}
        style={{
          position: "absolute",
          width: 40,
          height: 40,
          top: "unset",
        }}
      />
    ) : null;
  }

  const onHideAcquisitionOffers = () => {
    setAcquisitionOffers([]);
    setShowAcquisitionOffers(false);
  };

  const onHideClickInsReport = () => {
    setShowClickInsReport(false);
  };

  const onHideLeadsGrid = () => {
    setShowLeadsGrid(false);
    onClearLeads();
  };

  function onChangeExternalDataStats(value: number): void {
    setChosenSource(value);
    setSourceFilterValue2(
      value === 1
        ? AcquisitionSourceLabel.MY_ACQUISITIONS_SALE
        : value === 0
        ? AcquisitionSourceLabel.MY_ACQUISITIONS_SERVICE
        : ""
    );
  }

  const transformImages = (reportVisuals) => {
    return reportVisuals?.map((reportVisual, index) => ({
      itemImageSrc: reportVisual?.fullSizeOriginalImagePath,
      thumbnailImageSrc:
        reportVisual?.thumbnailOriginalImagePath ||
        reportVisual?.fullSizeOriginalImagePath, // Use thumbnail if available, otherwise use full size
      alt: `Image ${index + 1}`,
      title: `Title ${index + 1}`,
    }));
  };

  const images = transformImages(
    clickInsReport && clickInsReport["reportVisuals"]
  );

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const MilesComponent = ({ miles }) => (
    <NumericFormat
      value={miles}
      displayType={"text"}
      thousandSeparator={true}
      suffix={" miles"}
      renderText={(value) => <div>{value}</div>}
    />
  );

  const handleRowClickWithoutClickEvents = (event) => {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
  };

  const cellTemplateWithoutClickEvents = (rowData, column) => {
    const fieldValue = rowData[column.field];

    return <div onClick={handleCellWithoutClickEvents}>{fieldValue}</div>;
  };

  return (
    <div
      id={"visitor-external-data-mytraffic"}
      className={
        "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
      }
      style={{ overflowX: "auto" }}
    >
      <div style={{ display: "flex", marginBottom: 20, marginLeft: 5 }}>
        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2PhoneNumber(e.target.value);
            }}
            value={t2PhoneNumber}
          />
        </div>

        <div style={{ width: 200, marginRight: 20 }}>
          <label htmlFor="email">Email:</label>
          <InputText
            style={{ width: "100%" }}
            onChange={(e: any) => {
              setT2Email(e.target.value);
            }}
            value={t2Email}
          />
        </div>

        <div style={{ width: 100, marginTop: 18 }}>
          <Button
            label={"Send"}
            onClick={handleSubmit}
            className={"p-button-secondary no-icon-buttons"}
          />
        </div>

        <div style={{ width: 120, marginTop: 18 }}>
          <Button
            className={"p-button-secondary no-icon-buttons"}
            label={"Clear"}
            onClick={() => {
              sortMap.clear();
              setPhoneFilterValue("");
              setEmailFilterValue("");
              setFullnameFilterValue("");
              setVehicleMakeFilterValue("");
              setVehicleYearFilterValue("");
              setSourceFilterValue("");
              setSourceFilterValue2("");
              setVehicleModelFilterValue("");
              setVehicleDatePurchasedFilterValue("");
              setVehicleSeriesFilterValue("");
              setInspectionStatusFilterValue("");
              setVehiclePlateNumberFilterValue("");
              setVehicleVinFilterValue("");
              setVehicleVehicleTypeFilterValue("");
              setPiiAddressFilterValue("");
              setPiiCityFilterValue("");
              setPiiStateFilterValue("");
              setPiiZipCodeFilterValue("");
              setPurchasedDatePeriod({
                start: undefined,
                end: undefined,
              });
              setT2PhoneNumber("");
              setT2Email("");
            }}
          />
        </div>
        <div style={{ width: 150, marginTop: 18, marginLeft: 20 }}>
          {Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) && (
            <Button
              style={{ width: "100%" }}
              className={"p-button-secondary"}
              id={IDS.acquisitions.button.wishList}
              label={t("wishList.labels.performanceHeader")}
              onClick={onPerformanceReport}
              icon={"pi pi-chart-bar"}
              disabled={
                !dealershipContext.leadRecordsEnabled &&
                !dealershipContext?.iOfferEnabled
              }
            />
          )}
        </div>
        <div style={{ width: 150, marginTop: 18, marginLeft: 20 }}>
          {Util.hasAnyAuthority(PERMISSIONS.DEALERSHIP_MANAGEMENT) && (
            <Button
              style={{ width: "100%" }}
              className={"p-button-secondary"}
              id="instant-acquisition-button"
              label={t("wishList.labels.instantAcquisition")}
              onClick={onInstantAcquisition}
              icon={"pi pi-envelope"}
              disabled={selectedCustomers.length === 0}
            />
          )}
        </div>
        <div style={{ marginTop: 5, marginLeft: 30, display: "inline-flex" }}>
          <div
            className={"legend-item-stats"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            title="Number of ADFs sent on offer completion by customer"
          >
            <div
              className={"legend-bubble stats-bubbles"}
              style={{ border: "2px solid #397ddd", cursor: "pointer" }}
              onClick={(rowData: any) => onLeadsBubbleClick(rowData)}
            >
              <div className={"bubble-text"}>
                {externalDataStats?.leadsFromIOffer}
              </div>
            </div>
            <div className={"description"}>
              <p>Leads</p>
            </div>
          </div>
          <div
            className={"legend-item-stats"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            title="Number of Emails sent to Customers"
          >
            <div className={"legend-bubble stats-bubbles"}>
              <div className={"bubble-text"}>
                {externalDataStats?.emailSentCount}
              </div>
            </div>
            <div className={"description"}>
              <p>Emails Sent</p>
            </div>
          </div>
          <div
            className={"legend-item-stats"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            title="Number of unique Customers, based on their email"
          >
            <div className={"legend-bubble stats-bubbles"}>
              <div className={"bubble-text"}>
                {externalDataStats?.prospectCount}
              </div>
            </div>
            <div className={"description"}>
              <p>Prospects</p>
            </div>
          </div>
          <div
            className={"legend-item-stats"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            title="Number of vehicles that came to the dealership’s inventory after garage expansion / service appointment"
          >
            <div className={"legend-bubble stats-bubbles"}>
              <div className={"bubble-text"}>
                {externalDataStats?.acquisitionCount}
              </div>
            </div>
            <div className={"description"}>
              <p>Acquisitions</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="acquisition-grid"
        style={{ marginLeft: 5, width: "155vw" }}
      >
        {!inventoryStatus && !loading && (
          <div
            style={{
              height: 30,
              display: "inline-flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <i
              style={{ color: "#ff8600" }}
              className="pi pi-exclamation-triangle"
            />
            <p style={{ fontSize: "1rem", marginLeft: 10 }}>
              {t("no_inventory_configured")}
            </p>
          </div>
        )}
        <DataTable
          lazy={true}
          style={{ height: "600px" }}
          filterDisplay="row"
          loading={loading}
          sortMode={"multiple"}
          resizableColumns={true}
          columnResizeMode={"expand"}
          rowsPerPageOptions={Util.rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
          paginator={true}
          rows={limit}
          first={first}
          onPage={onPage}
          onSort={onSort}
          totalRecords={totalItemsCount}
          value={visitorsExternalData}
          autoLayout={true}
          multiSortMeta={Array.from(sortMap.values())}
          ref={dt}
          selection={selectedCustomers}
          selectionMode="checkbox"
          onSelectionChange={(e) => {
            setSelectedCustomers(e.value);
          }}
          onRowClick={handleRowClickWithoutClickEvents}
          rowClassName={() => {
            return { "no-click-events": true };
          }}
          header={
            <div
              style={{
                maxHeight: 70,
                width: "100%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", columnGap: 40 }}>
                <Button
                  id={IDS.button.clear}
                  icon={"pi pi-filter"}
                  label={t("clear")}
                  onClick={onClear}
                />
                {/* <DateRangePickerWidget
                  start={calendarFilter?.period?.start}
                  end={calendarFilter?.period?.end}
                  ranges={DateRangePeriods.options}
                  onChange={onPeriodChange}
                /> */}
                <RadioFilter
                  name={"product-type-radio"}
                  onChange={onChangeExternalDataStats}
                  options={[
                    {
                      label: "myAcquisitions - Sales",
                      value: AcquisitionSource.MY_ACQUISITIONS_SALE,
                      title: t("auto_acquire"),
                    },
                    {
                      label: "myAcquisitions - Service",
                      value: AcquisitionSource.MY_ACQUISITIONS_SERVICE,
                      title: t("vehicle_exchange"),
                    },
                    {
                      label: t("all"),
                      value: AcquisitionSource.all,
                      title: t("both_aa_vex"),
                    },
                  ]}
                  selected={chosenSource}
                />
              </div>
              <Button
                style={{ float: "right" }}
                icon={"pi pi-download"}
                label={"Export"}
                title={"Export Leads as CSV"}
                onClick={handleExportSubmit}
              />
            </div>
          }
        >
          <Column
            selectionMode="multiple"
            style={{
              width: "3em",
              flex: "0.1",
              textAlign: "center",
              pointerEvents: "all",
            }}
          />
          <Column
            style={{ width: 100, textAlign: "center", overflow: "visible" }}
            sortable={true}
            filter={true}
            field={"created"}
            body={dateCreatedTemplate}
            header={"Date Created"}
            filterElement={dateCreatedFilter}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiFullName"}
            header={"Full Name"}
            filterElement={fullnameFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiAddress"}
            header={"Address"}
            filterElement={piiAddressFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiCity"}
            header={"City"}
            filterElement={piiCityFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiState"}
            header={"State"}
            filterElement={piiStateFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiZipcode"}
            header={"Zip Code"}
            filterElement={piiZipCodeFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiPhone"}
            header={"Phone"}
            filterElement={phoneFilter}
            body={(rowData) => {
              return (
                <div
                  style={{ whiteSpace: "pre-line" }}
                  onClick={handleCellWithoutClickEvents}
                >
                  {rowData?.piiPhone?.split(",").join("\n")}
                </div>
              );
            }}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"piiEmails"}
            header={"Emails"}
            filterElement={emailFilter}
            body={(rowData) => {
              return (
                <div
                  style={{ whiteSpace: "pre-line" }}
                  onClick={handleCellWithoutClickEvents}
                >
                  <p
                    title="myAcquisition emailed to customer"
                    style={{ color: "blue", marginTop: 0, marginBottom: 0 }}
                  >
                    {rowData?.piiEmails
                      ?.split(",")
                      .includes(rowData?.initialEmail)
                      ? rowData?.initialEmail
                      : null}
                  </p>
                  {rowData?.piiEmails
                    ?.split(",")
                    .includes(rowData?.initialEmail)
                    ? rowData.piiEmails
                        ?.split(",")
                        .filter((element) => element !== rowData?.initialEmail)
                        .join("\n")
                    : rowData?.piiEmails?.split(",").join("\n")}
                </div>
              );
            }}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleYear"}
            header={"Year"}
            filterElement={vehicleYearFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleMake"}
            header={"Make"}
            filterElement={vehicleMakeFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleModel"}
            header={"Model"}
            filterElement={vehicleModelFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleSeries"}
            header={"Trim"}
            filterElement={vehicleSeriesFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"inspectionStatus"}
            header={"Inspection Status"}
            filterElement={inspectionStatusFilter}
            body={(rowData: any) => {
              return rowData?.inspectionStatus === "LINK SENT" ? (
                <>
                  <CopyToClipboard
                    text={`${rowData?.clickInsUrl}`}
                    onCopy={() => Util.success(t("copiedToClipboard"))}
                  >
                    <i
                      className="pi pi-copy"
                      title="Copy Inspection Link"
                      style={{ cursor: "pointer", pointerEvents: "all" }}
                    />
                  </CopyToClipboard>
                  <p style={{ cursor: "pointer", pointerEvents: "all" }}>
                    {rowData?.inspectionStatus}
                  </p>
                </>
              ) : (
                <div
                  title="Click to display Click-Ins Report"
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  onClick={() =>
                    rowData?.inspectionStatus === "COMPLETED"
                      ? onInspectionStatusClick(rowData)
                      : null
                  }
                >
                  <p style={{ cursor: "pointer", pointerEvents: "all" }}>
                    {rowData?.inspectionStatus}
                  </p>
                  {rowData?.inspectionStatus === "COMPLETED" && (
                    <i className="pi pi-arrow-up-right" />
                  )}
                </div>
              );
            }}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehiclePlateNumber"}
            header={"Plate Number"}
            filterElement={vehiclePlateNumberFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleVin"}
            header={"Vin"}
            filterElement={vehicleVinFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"vehicleVehicleType"}
            header={"Vehicle Type"}
            filterElement={vehicleVehicleTypeFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100, textAlign: "center", overflow: "visible" }}
            sortable={true}
            filter={true}
            field={"firstEmailSentTs"}
            body={emailDateTemplate}
            header={"Emailed Date"}
            filterElement={emailedDateFilter}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"offerPrice"}
            header={"Offer"}
            body={(rowData: any) => {
              const handleOfferPriceClick = (event) => {
                event.stopPropagation(); // Stop event propagation
                event.preventDefault(); // Prevent default behavior
                onOfferPriceClick(rowData); // Call your existing function
              };

              return (
                <div
                  title="Click to display all prices"
                  onClick={(event) => handleOfferPriceClick(event)} // Pass the event
                >
                  {rowData?.offerPrice === null ? (
                    <p></p>
                  ) : Number(rowData?.offerPrice) === 0 ? (
                    <p title="Vehicle is not driveable!">0$</p>
                  ) : (
                    <p>
                      {"$" +
                        Number(rowData?.offerPrice).toLocaleString("en-US")}
                    </p>
                  )}
                  {rowData?.offerPrice !== null && (
                    <i
                      style={{ cursor: "pointer", pointerEvents: "all" }}
                      className="pi pi-arrow-up-right"
                    />
                  )}
                </div>
              );
            }}
            filterElement={offerPriceFilter}
          />
          <Column
            style={{ width: 100, textAlign: "center", overflow: "visible" }}
            sortable={true}
            filter={true}
            field={"acquiredDate"}
            body={acquiredDateTemplate}
            header={"Acquired Date"}
            filterElement={acquiredDateFilter}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"source"}
            header={"Source"}
            body={(rowData: any) => {
              return (
                <div onClick={(event) => handleCellWithoutClickEvents(event)}>
                  <p>{!isNull(rowData?.source) ? rowData?.source : ""}</p>
                </div>
              );
            }}
            filterElement={sourceFilter}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"leadStatus"}
            header={"Lead Status"}
            filterElement={leadStatusFilter}
            body={(rowData) => {
              return (
                <>
                  {String(rowData?.leadStatus).toLowerCase() == "sold" ? (
                    <p
                      style={{ color: "#36B37E", fontWeight: "bolder" }}
                      onClick={handleCellWithoutClickEvents}
                    >
                      {rowData?.leadStatus}
                    </p>
                  ) : (
                    <p>{rowData?.leadStatus}</p>
                  )}
                </>
              );
            }}
          />
          <Column
            style={{ width: 100 }}
            sortable={true}
            filter={true}
            field={"upType"}
            header={"Up Type"}
            filterElement={upTypeFilter}
            body={cellTemplateWithoutClickEvents}
          />
          <Column
            style={{ width: 100, textAlign: "center", overflow: "visible" }}
            sortable={true}
            filter={true}
            field={"vehicleDatePurchased"}
            body={createdTemplate}
            header={"Date Purchased"}
            filterElement={dateFilter}
          />
        </DataTable>
      </div>
      <Dialog
        modal={true}
        visible={t2ModalVisible}
        onHide={onHideT2Modal}
        header={""}
      >
        {(() => {
          let pii = visitorsT2ExternalData?.PII;
          let vehicles = visitorsT2ExternalData?.Vehicles;
          if (pii || vehicles) {
            return (
              <>
                <h4>Registered PII to this lead:</h4>
              </>
            );
          } else {
            return null;
          }
        })()}
        {(() => {
          let pii = visitorsT2ExternalData?.PII;
          if (pii) {
            return (
              <>
                {pii.map((item, index) => (
                  <div key={index}>
                    {
                      // Render specific fields first: address -> city -> state -> zip code
                      ["address", "city", "state", "zipcode"].map((field) => {
                        let value = item[field];
                        let displayKey = field;

                        if (value !== undefined) {
                          if (
                            Array.isArray(value) &&
                            (field === "emails" || field === "phone")
                          ) {
                            value = value.join(",");
                          }

                          displayKey = displayKey
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase());

                          return (
                            <p key={field}>
                              <strong>{displayKey}:</strong> {value ?? "N/A"}
                            </p>
                          );
                        }
                        return null;
                      })
                    }

                    {
                      // Render other fields in the object
                      Object.entries(item).map(([key, value]) => {
                        if (
                          ![
                            "address",
                            "city",
                            "state",
                            "zipcode",
                            "allFieldsNull",
                          ].includes(key)
                        ) {
                          if (key === "emails" && Array.isArray(value)) {
                            value = value.join(", ");
                          }
                          if (key === "phone" && Array.isArray(value)) {
                            value = value.join(", ");
                          }
                          key = key
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                          return (
                            <p key={key}>
                              <strong>{key}:</strong> {value ?? "N/A"}
                            </p>
                          );
                        }
                        return null;
                      })
                    }

                    <hr />
                  </div>
                ))}
              </>
            );
          } else {
            return null;
          }
        })()}
        {(() => {
          let vehicles = visitorsT2ExternalData?.Vehicles;
          if (vehicles) {
            return (
              <>
                <h4>Registered vehicles to this lead:</h4>
                {vehicles.map((vehicle, index) => (
                  <div key={index}>
                    <h5>Vehicle # {index + 1}</h5>

                    {
                      // Render specific fields first
                      vehicleOrderFields.map((field) => {
                        let value = vehicle[field];
                        let displayKey = field;

                        if (field === "series") {
                          displayKey = "Trim";
                        }

                        if (value !== undefined) {
                          return (
                            <p key={field}>
                              <strong>
                                {displayKey
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                                :
                              </strong>{" "}
                              {value ?? "N/A"}
                            </p>
                          );
                        }
                        return null;
                      })
                    }

                    {
                      // Render other fields in the object
                      Object.entries(vehicle).map(([key, value]) => {
                        if (!vehicleOrderFields.includes(key)) {
                          return (
                            <p key={key}>
                              <strong>
                                {key
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                                :
                              </strong>{" "}
                              {value ?? "N/A"}
                            </p>
                          );
                        }
                        return null;
                      })
                    }

                    <hr />
                  </div>
                ))}
              </>
            );
          } else {
            return null;
          }
        })()}
      </Dialog>
      <Dialog
        modal={true}
        visible={exportModalVisible}
        onHide={onHideExportModal}
        header={""}
        style={{ width: "60vw", height: "60vh" }}
      >
        <div style={{ width: "90%", marginLeft: "6vh", marginTop: 20 }}>
          <label htmlFor="dateInputExport">Columns to export</label>
          <Select
            isMulti={true}
            id={IDS.acquisitions.inputs.fieldsFilter}
            //filter={true}
            value={fieldsToFilterExport}
            options={exportFieldValues}
            className={"multi-select"}
            //style={{ width: "100%", boxShadow: "none" }}
            onChange={(values: any) => setFieldsToFilterExport(values || [])}
            closeMenuOnSelect={false}
          />
          <small id="export-filter-fields-help">
            If no specific columns are selected, the exported file will contain
            all the columns.
          </small>
        </div>

        <div
          style={{
            width: "90%",
            marginLeft: "6vh",
            height: "65%",
            position: "relative",
          }}
        >
          <Button
            style={{
              float: "right",
              position: "absolute",
              bottom: "1rem",
              right: "0rem",
            }}
            icon={"pi pi-download"}
            label={"Export"}
            title={"Export Leads as CSV"}
            onClick={onExport}
            disabled={exportDisabled}
          />
        </div>
      </Dialog>
      <Dialog
        modal={true}
        style={{ width: "80vw" }}
        visible={showAcquisitionOffers}
        onHide={onHideAcquisitionOffers}
        header={"Acquisition Offers"}
      >
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          {getSpinner()}
          <DataTable
            resizableColumns={true}
            columnResizeMode={"expand"}
            rowsPerPageOptions={Util.rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            value={acquisitionOffers}
            sortField="created"
            sortOrder={-1}
          >
            <Column
              style={{ width: 100 }}
              sortable
              field={"offerPrice"}
              body={(rowData: any) => (
                <p style={{ margin: 0 }}>
                  ${Number(rowData?.offerPrice).toLocaleString("en-US")}
                </p>
              )}
              header={"Offer Price"}
            />
            <Column
              style={{ width: 100 }}
              sortable
              field={"typicalMiles"}
              header={"Typical Miles"}
              body={(rowData) => {
                const formattedNumber = new Intl.NumberFormat("en-US").format(
                  rowData?.typicalMiles
                );
                return <p>{formattedNumber}</p>;
              }}
            />
            <Column
              style={{ width: 100 }}
              sortable={true}
              field={"offerSource"}
              header={"Source"}
              body={(rowData) => {
                const CamelCaseTitle = Util.dynamicCapitalize(
                  rowData?.offerSource
                );
                return <p>{CamelCaseTitle}</p>;
              }}
            />
            <Column
              style={{ width: 100 }}
              sortable
              field={"emailSentTs"}
              body={(rowData: any) => (
                <p>
                  {rowData.emailSentTs == "noemail"
                    ? "No Email"
                    : moment(rowData.emailSentTs).format(Util.usDateTimeFormat)}
                </p>
              )}
              header={"Email Sent"}
            />
            <Column
              style={{ width: 100 }}
              sortable={true}
              field={"created"}
              body={dateCreatedOffersTemplate}
              header={"Date Created"}
            />
          </DataTable>
        </div>
      </Dialog>
      <Dialog
        modal={true}
        style={{ width: "80vw", maxHeight: "70%" }}
        visible={showClickInsReport}
        onHide={onHideClickInsReport}
        header={"Click-Ins Report"}
      >
        <div>
          <div className="click-ins-rep-title">
            <p>VEHICLE INSPECTION REPORT</p>
          </div>
          <div className="click-ins-rep">
            <div className="click-ins-rep-body">
              <p>
                {clickInsReport &&
                  clickInsReport["inspectionCase"] &&
                  clickInsReport["inspectionCase"]?.modelYear +
                    " " +
                    clickInsReport["inspectionCase"]?.modelMake +
                    " " +
                    clickInsReport["inspectionCase"]?.modelModel +
                    " " +
                    clickInsReport["inspectionCase"]?.modelSubmodel}
              </p>
              <p>
                VIN# {clickInsReport && clickInsReport["inspectionCase"]?.vin}
              </p>
            </div>
            <div className="click-ins-customer-body">
              <div>
                <p>
                  <b>Customer:</b>{" "}
                  {clickInsReport &&
                    clickInsReport["inspectionCase"] &&
                    clickInsReport["inspectionCase"]?.clientInspectorName}
                </p>
                <p>
                  <b>Location:</b>{" "}
                  {"12950 FAWN MEADOW RD, DE SOTO, MO 63020, USA"}
                </p>
              </div>
              <div>
                <p>
                  <b>Timestamp:</b>{" "}
                  {formatDateTime(
                    moment(
                      clickInsReport &&
                        clickInsReport["inspectionCase"] &&
                        clickInsReport["inspectionCase"]?.createdOn
                    ).format(Util.usDateTimeFormat)
                  )}
                </p>
                <p>
                  <b>Type:</b>{" "}
                  {clickInsReport &&
                    clickInsReport["inspectionCase"] &&
                    clickInsReport["inspectionCase"]?.inspectionType}
                </p>
              </div>
            </div>
            <div className="car-info-click-ins">
              <div>
                <p>
                  <img
                    src={"assets/icons/car.png"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  <b>Trim: </b>
                  {clickInsReport &&
                    clickInsReport["inspectionCase"] &&
                    clickInsReport["inspectionCase"]?.modelSubmodel}
                </p>
                <p>
                  <img
                    src={"assets/icons/transmission.png"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  <b>Drivetrain: </b>
                  {"AWD"}
                </p>
                <p>
                  <img
                    src={"assets/icons/car-engine.png"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  <b>Engine: </b>
                  {"2.4L 4-cyl"}
                </p>
                <p>
                  <img
                    src={"assets/icons/fuel.png"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  <b>Fuel type: </b>
                  {clickInsReport &&
                    clickInsReport["inspectionCase"] &&
                    clickInsReport["inspectionCase"]?.fuel}
                </p>
              </div>
              <div>
                <p style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={"assets/icons/road.png"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  <b>Miles: </b>
                  <MilesComponent
                    miles={
                      clickInsReport &&
                      clickInsReport["inspectionCase"] &&
                      clickInsReport["inspectionCase"]?.odometer
                    }
                  />
                </p>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px solid black",
                      background: "black",
                      width: 20,
                      height: 20,
                      marginBottom: 0,
                      marginRight: "10px",
                    }}
                  ></p>
                  <b>Exterior Color: </b>
                  {"Eternal Blue Mica"}
                </p>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px solid #115a70",
                      background: "#115a70",
                      width: 20,
                      height: 20,
                      marginBottom: 0,
                      marginRight: "10px",
                    }}
                  ></p>
                  <b>Interior Color: </b>
                  {"Black Leather"}
                </p>
              </div>
            </div>
          </div>
          <div className="vehicle-photos">
            <div className="vehicle-photos-header">
              <b>Exterior: </b>
              {clickInsReport &&
                clickInsReport["reportVisuals"] &&
                clickInsReport["reportVisuals"]?.length}
            </div>
            <div className="vehicle-photos-body">
              {clickInsReport &&
                clickInsReport["reportVisuals"] &&
                clickInsReport["reportVisuals"]?.map((reportVisual, index) => {
                  return (
                    <img
                      src={reportVisual?.fullSizeOriginalImagePath}
                      width={300}
                      height={200}
                      style={{ margin: 10, cursor: "pointer" }}
                      key={index}
                      onClick={() => openModal(index)}
                    />
                  );
                })}

              <ImageGallery
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                images={images}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
              />
            </div>
          </div>
          <div className="vehicle-photos">
            <div className="vehicle-photos-header">
              <b>Interior: </b>
              {clickInsReport &&
                clickInsReport["reportVisuals"] &&
                clickInsReport["reportVisuals"]?.length}
            </div>
          </div>
          <div className="exterior-data">
            <div className="vehicle-photos-header">
              <b>Damage detected: </b>
              {clickInsReport &&
                clickInsReport["inspectionCase"] &&
                clickInsReport["inspectionCase"]?.metadata?.damage_recognition
                  ?.length}
            </div>
            <div className="damage-recognition-element">
              {clickInsReport &&
                clickInsReport["inspectionCase"] &&
                clickInsReport[
                  "inspectionCase"
                ]?.metadata?.damage_recognition?.map((damage, index) => {
                  const croppedImg =
                    clickInsReport &&
                    clickInsReport["reportVisuals"] &&
                    clickInsReport["reportVisuals"]?.filter(
                      (reportVisual) =>
                        reportVisual?.uploadedImageId ===
                        damage?.ref_images[0]?.uploaded_image_id
                    );

                  return (
                    <div key={index} className="damage-element">
                      <div className="damage-image-div">
                        <img
                          src={croppedImg[0]?.fullSizeDamageOverlayPath}
                          width={150}
                          height={100}
                        />
                      </div>
                      <div className="damage-properties-div">
                        <p>ID: {damage?.part?.part_id}</p>
                        <p>Location: {damage?.side?.replace("_", " ")}</p>
                        <p>Component: {damage?.part?.part_description}</p>
                        <p>Severity: {damage?.damage_severity}</p>
                        <p>Length: {damage?.damage_area}</p>
                      </div>
                    </div>
                  );
                })}
              {/* <DataTable
                value={
                  clickInsReport &&
                  clickInsReport["inspectionCase"] &&
                  clickInsReport["inspectionCase"]?.metadata?.damage_recognition
                }
                autoLayout={true}
              >
                <Column
                  field={"side"}
                  header={"SIDE"}
                  body={(rowData) => <p>{rowData?.side?.replace("_", " ")}</p>}
                />
                <Column field={"part.part_description"} header={"COMPONENT"} />
                <Column
                  field={"ref_images"}
                  header={"CROPPED PHOTO"}
                  body={(rowData) => {
                    const croppedImg =
                      clickInsReport &&
                      clickInsReport["reportVisuals"] &&
                      clickInsReport["reportVisuals"]?.filter(
                        (reportVisual) =>
                          reportVisual?.uploadedImageId ===
                          rowData?.ref_images[0]?.uploaded_image_id
                      );

                    return (
                      <div>
                        {croppedImg && croppedImg?.length > 0 && (
                          <img
                            src={croppedImg[0]?.fullSizeDamageOverlayPath}
                            width={150}
                            height={100}
                          />
                        )}
                      </div>
                    );
                  }}
                />
                <Column field={"damage_type"} header={"DAMAGE TYPE"} />
                <Column field={"damage_severity"} header={"DAMAGE SEVERITY"} />
                <Column field={""} header={"REPAIR METHOD"} />
              </DataTable> */}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        modal={true}
        style={{ width: "80vw" }}
        visible={showLeadsGrid}
        onHide={onHideLeadsGrid}
        header={"Acquisition Leads"}
      >
        <div
          className={
            "p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows"
          }
        >
          {/* {getSpinner()} */}
          <DataTable
            lazy={true}
            resizableColumns={true}
            loading={loadingLeads}
            filterDisplay="row"
            columnResizeMode={"expand"}
            rowsPerPageOptions={Util.rowsPerPageOptions}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            value={acquisitionLeads}
            rows={limitLeads}
            first={firstLeads}
            paginator={true}
            onPage={onPageLeads}
            onSort={onSortLeads}
            totalRecords={totalItemsLeadsCount}
            autoLayout={true}
            sortField="created"
            sortMode={"multiple"}
            sortOrder={-1}
            multiSortMeta={Array.from(sortMapLeads.values())}
            header={
              <div
                style={{
                  maxHeight: 70,
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", columnGap: 40 }}>
                  <Button
                    id={IDS.button.clear}
                    icon={"pi pi-filter"}
                    label={t("clear")}
                    onClick={onClearLeads}
                  />
                </div>
              </div>
            }
          >
            <Column
              style={{ width: 100 }}
              sortable={true}
              filter={true}
              field={"fullName"}
              header={"Full Name"}
              filterElement={fullnameLeadsFilter}
            />
            <Column
              style={{ width: 100 }}
              sortable={true}
              filter={true}
              field={"email"}
              header={"Email"}
              filterElement={emailLeadsFilter}
            />
            <Column
              style={{ width: 100 }}
              sortable={true}
              filter={true}
              field={"phone"}
              header={"Phone"}
              filterElement={phoneLeadsFilter}
              body={(rowData) => {
                return (
                  <div style={{ whiteSpace: "pre-line" }}>
                    {rowData?.phone?.split(",").join("\n")}
                  </div>
                );
              }}
            />
            <Column
              style={{ width: 100 }}
              sortable={true}
              filter={true}
              field={"source"}
              header={"Source"}
              body={(rowData: any) => (
                <p>{!isNull(rowData?.source) ? rowData?.source : t("ALL")}</p>
              )}
              filterElement={sourceLeadsFilter}
            />
            <Column
              style={{ width: 100, textAlign: "center", overflow: "visible" }}
              sortable={true}
              filter={true}
              field={"prospectLeadDate"}
              body={dateLeadsTemplate}
              header={"Date"}
              filterElement={dateLeadsFilter}
            />
          </DataTable>
        </div>
      </Dialog>
      <Dialog
        modal={true}
        style={{ width: "50vw", maxWidth: "700px", minWidth: "350px" }}
        visible={isInstantAcquisitionModalOpen}
        onHide={onHideInstantAcquisition}
        header={"Send Instant Acquisition Email"}
      >
        <div className="p-grid">
          <div className="p-col-12 p-lg-4 p-label-col">
            <div className="label-container">
              <label className="input-label">To:</label>
              <br />
              <small>
                <i>(Emails are individually sent)</i>
              </small>
            </div>
          </div>
        </div>
        <div className="p-grid">
          <div id="recipients-instant-acquisition" className="p-col-12 p-lg-12">
            <TagsInput
              value={Util.getStringArray(combinedEmailsString)}
              disabled={true}
              inputProps={{
                disabled: true,
                placeholder: null,
                style: { display: "none" },
              }}
              onChange={() => {}}
            />
          </div>
        </div>

        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-3 p-label-col"}>
            <div className={"label-container"}>
              <label className={"input-label"}>Email Subject:</label>
            </div>
          </div>
        </div>
        <div className={"p-grid"}>
          <div
            id={IDS.dealershipsForm.inputs.partnerDestinationURL}
            className={"p-col-12 p-lg-12"}
          >
            <InputText
              id={IDS.dealershipsForm.inputs.partnerDestinationURL}
              style={{ width: "100%" }}
              name={"emailSubject"}
              placeholder={"Subject"}
              value={instantAcquisitionEmailSubject}
              maxLength={100}
              onChange={(e: any) =>
                setInstantAcquisitionEmailSubject(e.target.value)
              }
            />
          </div>
        </div>

        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-3 p-label-col"}>
            <div className={"label-container"}>
              <label className={"input-label"}>Email Body:</label>
              <br />
              <small>
                <i>Type '$' for a list of available variables</i>
              </small>
            </div>
          </div>
        </div>
        <div className={"p-grid"}>
          <div
            id={IDS.dealershipsForm.inputs.partnerDestinationURL}
            className={"p-col-12 p-lg-12"}
          >
            <InputTextarea
              ref={textareaRef}
              value={instantAcquisitionEmailBodyText}
              style={{
                width: "100%",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: 200,
              }}
              onChange={handleChange}
              placeholder="Type your email body here..."
              rows={8}
            />

            {showDropdown && (
              <ul
                style={{
                  left: "10px",
                  top: "calc(100% - 30px)",
                  width: "100%",
                  border: "1px solid #ccc",
                  backgroundColor: "white",
                  listStyle: "none",
                  padding: "5px",
                  margin: "0",
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                  zIndex: 10,
                  maxHeight: "150px",
                  overflowY: "auto",
                }}
              >
                {instantAcquisitionVariables.map((variable) => (
                  <li
                    key={variable.key}
                    onClick={() => handleSelectVariable(variable.key)}
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{`\${${variable.key}}`}</strong> -{" "}
                    {variable.description}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div
          style={{
            width: "90%",
            marginLeft: "-2vw",
            height: "65%",
            position: "relative",
            marginTop: "7vh",
          }}
        >
          <Button
            style={{
              float: "right",
              position: "absolute",
              bottom: "1rem",
              right: "0rem",
            }}
            icon={"pi pi-send"}
            label={"Send"}
            title={"Send email to recipients"}
            onClick={onSubmitInstantAcquisitionEmail}
          />

          <Button
            style={{
              float: "right",
              position: "absolute",
              bottom: "1rem",
              right: "-5rem",
            }}
            className={"p-button-warning"}
            icon={"pi pi-arrow-left"}
            label={"Back"}
            title={"close"}
            onClick={onHideInstantAcquisition}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Acquisitions;
